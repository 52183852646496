import React from 'react'
import PropTypes from 'prop-types'
// import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const FeedEntryTemplate = ({ content, contentComponent, description, date, image, title, helmet }) => {
  const FeedEntryContent = contentComponent || Content
  return (
    <div className="entry">
      {helmet || ''}
      <div className="entry__image">
        <PreviewCompatibleImage
          imageStyle={{
            position: 'relative',
            objectFit: 'contain',
            objectPosition: '50% 50%',
            backgroundColor: 'transparent',
          }}
          imageInfo={{ image: image, alt: title }}
        />
      </div>
      <div className="entry__date">
        <p>{date}</p>
      </div>
      <div className="entry__title">
        <h1>{title}</h1>
      </div>
      {description ? (
        <div className="entry__desc">
          <p>{description}</p>
        </div>
      ) : (
        ''
      )}
      <div className="entry__content">
        <FeedEntryContent content={content} />
      </div>
    </div>
  )
}

FeedEntryTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  featuredImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet),
}

const FeedEntry = ({ data }) => {
  const { markdownRemark: feedEntry } = data
  return (
    <FeedEntryTemplate
      content={feedEntry.html}
      contentComponent={HTMLContent}
      image={feedEntry.frontmatter.featuredImage}
      description={feedEntry.frontmatter.description}
      date={feedEntry.frontmatter.date}
      helmet={
        <Helmet>
          <title>{`${feedEntry.frontmatter.title} | Morbid Books`}</title>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@morbidbooks" />
          <meta name="twitter:title" content={feedEntry.frontmatter.title} />
          <meta name="twitter:description" content={feedEntry.frontmatter.description} />
          <meta
            name="twitter:image"
            content={`https://morbidbooks.net${feedEntry.frontmatter.featuredImage.childImageSharp.fluid.src}`}
          />
        </Helmet>
      }
      title={feedEntry.frontmatter.title}
    />
  )
}

FeedEntry.propTypes = {
  //   data: PropTypes.shape({
  //     markdownRemark: PropTypes.object,
  //   }),
  data: PropTypes.object.isRequired,
}

export default FeedEntry

export const pageQuery = graphql`
  query FeedEntryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        description
      }
    }
  }
`
